export default {
    data() {
        return {
            isIndeterminate: true,
            checkAll: false,
            checkedDatas: [],
            comVal: "",
            comValObj: {}
        }
    },
    methods: {
        backToInit(initArr) {
            // 恢复原值
            this.dataList = JSON.parse(JSON.stringify(initArr));
            this.comVal = "";
            this.comValObj = {};
            this.isIndeterminate = true;
            this.checkAll = false;
            this.checkedDatas = [];
        },
        batchChange(key, val) {
            // if (val) {
                this.checkedDatas.forEach((item) => {
                    let index = this.dataList.findIndex((d) => d.id == item);
                    this.$set(this.dataList[index], key, val);
                });
            // }
        },
        handleCheckAllChange(val) {
            this.checkedDatas = val ? this.dataList.map((item) => item.id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedDatasChange(key, initArr, value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.dataList.length;
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.dataList.length;

            if (key instanceof Array) {
                if (value.length && Object.keys(this.comValObj).length) {
                    value.forEach((item) => {
                        let index = this.dataList.findIndex((d) => d.id == item);
                        key.forEach(k => {
                            this.$set(this.dataList[index], k, this.comValObj[k]);
                        })
                    });
                } else {
                    this.comValObj = {}
                }

            } else if (typeof key == "string") {
                if (value.length && this.comVal) {
                    value.forEach((item) => {
                        let index = this.dataList.findIndex((d) => d.id == item);
                        this.$set(this.dataList[index], key, this.comVal);
                    });
                } else {
                    this.comVal = ""
                }
            }


            let notSel = this.dataList.filter(item => value.every(v => v != item.id))
            notSel.forEach(item => {
                let index = this.dataList.findIndex((d) => d.id == item.id);
                if (key instanceof Array) {
                    key.forEach(k => {
                        this.$set(this.dataList[index], k, initArr[index][k]);
                    })
                } else if (typeof key == "string") {
                    this.$set(this.dataList[index], key, initArr[index][key]);
                }

            })
        },
    }
}