import { domainsByFbpixels,historyInput } from '@/api/creatAd.js'
export default {
    data() {
        return {
            apiWebDomains: [],
            iptWebDomains: [],
            urlWebDomains: "",
        }
    },
    computed: {
        webDomains() {
            let arr = this.apiWebDomains.concat(this.domainHistory,this.urlWebDomains, this.iptWebDomains);
            return this.sortUniq(arr)
        }
    },
    methods: {
        sortUniq(arr) {
            let _arr = arr
            let sortedArr = Array.from(new Set(_arr))
            sortedArr.sort((a, b) => {
                return a.localeCompare(b)
            })
            return [...sortedArr]
        },
        domainsByFbpixels(fbPixels) {
            let params = {
                fbPixels
            }
            return domainsByFbpixels(params).then(res => {
                if (res.code == 0) {
                    this.apiWebDomains = this.sortUniq(res.data)
                }
                return res
            })
        },
        
    }
}